<template>
  <ion-page>
    <ion-header>
      <MainToolbar :title="i18n.$t('track-infect.discussion.title')" />
      <ion-toolbar>
        <div id="select-container">
          <ion-item>
            <ion-icon size="large" :icon="location"></ion-icon>
            <ion-select class="select-with-icon" interface="popover" value="global" :placeholder="i18n.$t('track-infect.discussion.location')">
              <ion-select-option value="global">Global</ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item>
            <ion-icon size="large" :icon="atCircle"></ion-icon>
            <ion-select class="select-with-icon" interface="popover" value="general" :placeholder="i18n.$t('track-infect.discussion.topic')">
              <ion-select-option value="general">General</ion-select-option>
            </ion-select>
          </ion-item>
        </div>
      </ion-toolbar>
      
    </ion-header>
    <ion-content>

      <ion-fab vertical="top" horizontal="end" slot="fixed" >
        <ion-fab-button @click="openModal()">
          <ion-icon :icon="filter"></ion-icon>
        </ion-fab-button>
      </ion-fab>

      <ion-fab vertical="bottom" horizontal="end" slot="fixed" >
        <ion-fab-button color="tertiary" @click="openModal()">
          <ion-icon :icon="add"></ion-icon>
        </ion-fab-button>
      </ion-fab>
      
      <ion-list id="main-list">
        <ion-card v-for="post in posts" :key="post.id"><ion-card-header>
          <ion-card-title class="post-title">
            <!-- TODO Check if avatar can be displayed, else use placeholder -->
            <div class="avatar"><!-- FIXME All Names are not centered next to avatar --> 
              <img v-if="post.creator.avatar" :src="post.creator.avatar">
              <ion-icon v-else :icon="personCircleOutline"></ion-icon>
            </div>
            <div class="creator-data">
              <span class="creator-name">{{ post.creator.name }}</span>
              <span class="creation-time">{{ formatTimestamp(post.createdAt) }}</span>
            </div>
            <div class="report-button-container">
              <ion-button class="report-button" @click="reportPost(post);" fill="clear" color="danger">
                <ion-icon :icon="alertCircleOutline" slot="icon-only"></ion-icon>
              </ion-button>
            </div>
          </ion-card-title>
        </ion-card-header>

        <ion-card-content>
          <ion-item class="post-content" lines="full">
            <p>{{ post.content }}</p>
          </ion-item>
          <ion-item :lines="post.comments_visible && post.comments && post.comments.length > 0 ? 'full' : 'none'">
            <ion-buttons class="post-button-container">
              <ion-button class="post-button" expand="full">
                <ion-icon :icon="shareSocial" slot="start"></ion-icon>
                Teilen
              </ion-button>
              <ion-button class="post-button" expand="full" @click="(!post.comments_visible) ? openComments(post) : closeComments(post)">
                <ion-icon :icon="chatbubbleEllipses" slot="start"></ion-icon>
                {{ post.comments_visible ? 'Schließen' : 'Kommentare' }} ({{ post.comment_count }})
              </ion-button>
            </ion-buttons>
          </ion-item>
          <ion-item v-if="post.comments_visible && post.comments && post.comments.length > 0" lines="none">
            <div class="comments-container">
              <ion-list>
                <ion-item v-for="comment in post.comments" :key="comment.id">
                  <div class="comment">
                    <div class="comment-title">
                      <div class="avatar">
                        <img v-if="post.creator.avatar" :src="post.creator.avatar">
                        <ion-icon v-else :icon="personCircleOutline"></ion-icon>
                      </div>
                      <div class="creator-data">
                        <span class="creator-name">{{ comment.creator.name }}</span>
                        <span class="creation-time">{{ formatTimestamp(comment.createdAt) }}</span>
                      </div>
                      <div class="report-button-container">
                        <ion-button class="report-button" @click="reportComment(comment);" fill="clear" color="danger">
                          <ion-icon :icon="alertCircleOutline" slot="icon-only"></ion-icon>
                        </ion-button>
                      </div>
                    </div>
                    <p>{{ comment.content }}</p>
                  </div>
                </ion-item>
              </ion-list>
              <ion-item lines="none">
                <a @click="loadComments(post);" href="javascript:void(0)">Weitere Kommentare anzeigen...</a> <!-- TODO Hide if no new comments available? But what if new comments get available during runtime? We dont know if some get added! --> 
              </ion-item>
              <ion-item lines="none">
                <div class="comment-input-container">
                  <div>
                    <ion-textarea placeholder="Kommentieren" class="comment-input" auto-grow="true" rows="1"></ion-textarea> <!-- TODO Add Submit Button and Make it look more like a input field --> 
                  </div>
                  <ion-button @click="submitComment(post);" fill="clear" color="primary"> <!-- TODO Kommentar hier als parameter von der Textarea einfügen -->
                    <ion-icon :icon="send" slot="start"></ion-icon>
                  </ion-button>
                </div>
              </ion-item>
            </div>
          </ion-item>
        </ion-card-content>
        </ion-card>
      </ion-list>

      <ion-infinite-scroll
        @ionInfinite="loadData($event)" 
        threshold="100px" 
        id="infinite-scroll"
        :disabled="infiniteScrollDisabled"
      >
        <ion-infinite-scroll-content
          loading-spinner="lines-small" 
          loading-text="Loading more data...">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonSelect,
  IonSelectOption,
  IonContent,
  modalController,
  IonList,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonIcon,
  IonFab,
  IonFabButton,
  IonButtons,
  IonButton,
  IonTextarea
} from '@ionic/vue';

import { personCircleOutline, location, atCircle, filter, add, shareSocial, chatbubbleEllipses, alertCircleOutline, send } from 'ionicons/icons';

import MainToolbar from '@/components/MainToolbar.vue';

import FilterPopup from '@/components/FilterPopup.vue';

import { onMounted, ref } from 'vue';

import { useI18n } from "@/utils/i18n";

export default  {
  name: 'Discussion',
  components: { 
    IonPage,
    IonHeader,
    IonToolbar,
    IonSelect,
    IonSelectOption,
    IonContent,
    MainToolbar,
    IonList,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonIcon,
    IonFab,
    IonFabButton,
    IonButtons,
    IonButton,
    IonTextarea
  },
  setup() {
    const i18n = useI18n();

    const infiniteScrollDisabled = ref(false);

    const posts = ref([]);

    const openModal = async function() {
      const modal = await modalController
        .create({
          component: FilterPopup,
          componentProps: {
            title: 'New Title'
          },
        })
      return modal.present();
    }

    var i = 0; //TODO For testing

    const loadData = function(event){//TODO API - Antwwort sollte auch immer enthalten, ob noch etwas kommt, oder nicht!
      setTimeout(() => {
        for (const lastI = i; i < lastI + 10; i++){
          posts.value.push({
            id: i,
            creator: {
              name: 'Dr. Max Mustermann',
            },
            createdAt: 1615464580,
            content: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
            comment_count: 10,
            comments_visible: false
          });
        }

        console.log(posts);

        if (event) event.target.complete();
      }, 2000);
    }

    const loadComments = function(post){ //TODO Immer schauen wieviele schon geladen und dann das nächste Batch ausgehend von dem letzten geladenen laden!
      if (!post.comments){
        post.comments = [];
      }
      let targetLength = post.comments.length + 3; 
      for (let j = post.comments.length; j < targetLength; j++){//TODO Nur als Test, später alles mit API
        post.comments.push({
          id: j,
          creator: {
            name: 'Dr. Erika Musterfrau',
          },
          createdAt: 1615559748,
          content: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
        })
      }//TODO Refresh comment count in post object with total possible count (might be new comments available)

    }

    const openComments = function(post){
      //If no comments are loaded yet, load them
      if (!post.comments || post.comments.length === 0) loadComments(post);
      //After load make them visible only if now comments are present
      post.comments_visible = true;
    }

    

    const closeComments = function(post){
      post.comments_visible = false;
    }

    const submitComment = function(post, content){ //TODO Use API
      post.comments.push({
        id: post.comments.length,
        creator: {
          name: 'Tobias',
        },
        createdAt: Math.round((new Date()).getTime()/1000),
        content: content
      }) //TODO Push return of API
    }

    const formatTimestamp = function(timestamp){
      let date = new Date(timestamp * 1000);
      return date.toLocaleString(i18n.$getCurrentProperties().full_locale);
    }

    onMounted(() => {
      loadData();
    });

    return { i18n,
      openModal,
      posts,
      infiniteScrollDisabled,
      loadData,
      personCircleOutline,
      location,
      atCircle,
      filter,
      add,
      shareSocial,
      chatbubbleEllipses,
      loadComments,
      openComments,
      closeComments,
      submitComment,
      formatTimestamp,
      alertCircleOutline,
      send
    };
  }
}
</script>

<style scoped>
#select-container {
  justify-content: space-evenly;
  align-items: center;
  display: flex;
  flex-flow: row;
}

#main-list {
  background: var(--ion-background-color);
}

ion-card-content {
  background-color: var(--ion-card-background, #fff);
}

ion-card-content ion-item {
  --background: var(--ion-card-background, #fff);
}

.avatar {
  display: inline;
  min-width: 30px;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  font-size: 24px;
  margin-right: 10px;
  overflow: hidden;
}

.avatar * {
  width: 30px;
  height: 30px;
}

.post-title, .comment-title {
  display: flex;
  align-items: center;
}

.post-title {
  padding-right: 36px;
}

.comment-title {
  margin-top: 10px;
  padding-right: 10px;
}

.creator-data {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.select-with-icon {
  margin-left: 10px;
}

.post-content > p, .comment > p {
  padding: 10px;
}

.post-button-container {
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
  width: 100%;
}

.post-button {
  text-transform: none;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 50%;
  margin: 0px;
}

.post-button:first-of-type {
  border-right: 1px solid;
  border-right-color: var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
}

.comments-container {
  display: flex;
  flex-flow: column;
}

.comment {
  display: flex;
  flex-flow: column;
}



.comment-input-container {
  width: 100%;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  background-color: rgba(var(--ion-color-medium-light-rgb), 0.3);
  margin: 5px;
  border-radius: 10px;
  padding-left: 10px;
}

.comment-input-container > div {
  width: 100%;
}

.comment-input-container > ion-button {
  --padding-start: 30px;
  --padding-end: 20px;
  height: 100%;
}

.comment-input {
  display: inline;
  width: 100%;
  margin: 0px;
  flex-grow: 1;
  
  --padding-top: 10px;
  --padding-bottom: 10px;
}

.comment-input textarea:empty {
  height: 3em;
}

.creator-name {
  margin-right: 10px;
}

.creation-time  {
  font-size: 14px;
  color: var(--ion-color-medium);
}

.report-button-container {
  margin-left: auto;
  font-size: 14px;
}

.report-button {
  opacity: 0.5;
  text-transform: none;
  margin: 0px;
  --padding-top: 0px;
  --padding-bottom: 0px;
  height: 30px;
  font-size: 14px;
}

.report-button:hover, .report-button:active, .report-button:focus {
  opacity: 1;
}
</style>
